export const SUPPORTED_FILE_FORMATS = [
  // AUDIO
  "mp3",
  "aac",
  "ogg",
  "flac",
  "wav",
  "m4a",
  "wma",
  "opus",
  // VIDEO
  "mp4",
  "mov",
  "mkv",
  "avi",
  "webm",
];

export const SUPPORTED_VIDEO_FORMATS = ["mp4", "mov", "mkv", "avi", "webm"];

export const SUPPORTED_AUDIO_FORMATS = [
  "mp3",
  "aac",
  "ogg",
  "flac",
  "wav",
  "m4a",
  "wma",
];
