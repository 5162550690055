export const BASIC_INFO = {
  GOOGLE_PLAY_APP_LINK:
    "https://play.google.com/store/apps/details?id=ai.audionotes.app",
  IOS_APP_LINK:
    "https://apps.apple.com/us/app/audionotes-speech-to-text/id6740337559",
  WHATS_APP_BOT_LINK: "https://wa.me/16464965161?text=start",
  TELEGRAM_BOT_LINK: "https://t.me/audionotes_ai_bot",
  ZAPIER_INTEGRATION_LINK: "https://zapier.com/apps/audionotesai/integrations",
  MAX_NOTES_FOR_UNREGISTERED: 3,
};
